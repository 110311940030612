/**
 * @file
 * Behaviors for the Timeline.
 */

!((Drupal, $) => {
  'use strict';
  const windowWidth = $(window).width();

  if (windowWidth < 768) {
    $('.expand-icon').on('click', function() {
      $(this)
        .parent()
        .next('.timeline-content__body')
        .slideToggle();
      $(this).toggleClass('expanded');
    });
  }
})(Drupal, jQuery);
